import { Container, Grid, IconButton } from "@material-ui/core";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useStyles } from "../../theme/frame";
import SideBarMobile from "../Sidebar/SidebarMobile";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import UserInfo from "../UserInfo";
var logo = require("../../assets/img/logo.svg");
import Button from "@material-ui/core/Button";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { getItemsNumInCart } from "../../util/helperFuncs";
import { PARTNER } from "../lib/data";
var AppBarComponent = function (props) {
    var classes = useStyles();
    var _a = useState(false), sidebarOpen = _a[0], setSideBarOpen = _a[1];
    var _b = useState(getItemsNumInCart()), inCartNum = _b[0], setInCartNum = _b[1];
    window.addEventListener("storage", function () {
        var curNum = getItemsNumInCart();
        if (curNum !== inCartNum)
            return setInCartNum(curNum);
    });
    var cartIcon = (React.createElement(Box, { display: "flex", position: "relative" },
        React.createElement(ShoppingCartIcon, null),
        inCartNum > 0 ? (React.createElement(Box, { position: "absolute", fontSize: 12, padding: "2px 4px", borderRadius: "50%", bgcolor: "red", color: "white", lineHeight: 1, right: "-10%", top: 0 }, inCartNum)) : null));
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { className: classes["sidebar--mobile"] },
            React.createElement(SideBarMobile, { type: props.type, open: sidebarOpen, sideBarCloseHanlder: function () { return setSideBarOpen(false); } })),
        React.createElement(AppBar, { className: classes.appbar, position: "sticky", color: "inherit", style: { maxWidth: "100%" } },
            React.createElement(Container, null,
                React.createElement(Grid, { container: true, spacing: 0 },
                    React.createElement(Grid, { item: true, xs: 3 },
                        React.createElement("img", { src: logo.default, className: classes.logo })),
                    React.createElement(Grid, { item: true, xs: 12, md: 5 },
                        React.createElement(UserInfo, { user: props.user })),
                    React.createElement(Grid, { item: true, xs: 8, sm: 9, md: 4, className: classes.links },
                        React.createElement(Link, { color: "textPrimary", component: NavLink, to: "/profile" },
                            React.createElement("span", { className: classes.desktop }, "\u041F\u0440\u043E\u0444\u0438\u043B\u044C"),
                            React.createElement("span", { className: classes.mobile },
                                React.createElement(AccountCircleIcon, null))),
                        React.createElement(Link, { color: "textPrimary", component: NavLink, to: "/", onClick: function (e) {
                                e.preventDefault();
                                props.logout();
                            } },
                            React.createElement("span", { className: classes.desktop }, "\u0412\u044B\u0445\u043E\u0434"),
                            React.createElement("span", { className: classes.mobile },
                                React.createElement(ExitToAppIcon, null))),
                        props.type === PARTNER && (React.createElement(Link, { color: "textPrimary", className: classes.mobile, component: NavLink, to: "/cart" }, cartIcon)),
                        props.type === PARTNER && (React.createElement(Button, { className: classes.desktop, to: "/cart", component: NavLink, startIcon: cartIcon }, "\u041A\u043E\u0440\u0437\u0438\u043D\u0430")),
                        React.createElement(IconButton, { onClick: function () { return setSideBarOpen(!sidebarOpen); } },
                            " ",
                            sidebarOpen ? React.createElement(CloseIcon, null) : React.createElement(MenuIcon, null))))))));
};
export default AppBarComponent;
