export default {
    app_language: "ru",
    api_dadata_key: "c958e5fa93eed735d48534807c4070f8477a0ed4",
    baseUrl:
        __mode__ == "development"
            ? "https://stage.lk.uniqtrip.ru/data"
            : "/data",
    env: "frontend",
    app_name: "Личный кабинет партнера",
    max_companies: 1,
    company_request_delay: 1000,
};
