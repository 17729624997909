import { createMuiTheme } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
const theme = createMuiTheme({
    typography: {
        fontFamily: "Panton, Helvetica, Sans serif",
        fontSize: 14,
        button: {
            textTransform: "none",
        },
        body1: {
            fontSize: 16,
        },
    },

    palette: {
        primary: {
            main: "#01BAC6",
            contrastText: "#FFFFFF",
        },
        secondary: {
            main: "#E30613",
            contrastText: "#FFFFFF",
        },
        text: {
            secondary: "#7C7C7C",
        },
    },
    overrides: {
        MuiListItem: {
            root: {
                fontFamily: "Panton, Helvetica, Sans serif",
                "&.active": {
                    backgroundColor: red[50],
                    color: red[500],
                },
            },
        },

        MuiPaper: {
            root: {
                maxWidth: 1106,
            },
        },
    },
});

export default theme;
