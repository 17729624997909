import axios from "./axios/webAxios";
var signIn = function (email, password) {
    var urlToSignin = "account/signin";
    return axios.post(urlToSignin, {
        email: email,
        password: password,
    });
};
var register = function (inn, person, phone, email, file, payment, password) {
    var formDataToSend = new FormData();
    formDataToSend.append("inn", inn);
    formDataToSend.append("person", person);
    formDataToSend.append("phone", phone);
    formDataToSend.append("email", email);
    formDataToSend.append("payment", payment);
    formDataToSend.append("password", password);
    if (file) {
        formDataToSend.append("file", file);
    }
    return axios({
        method: "post",
        url: "/account/register",
        data: formDataToSend,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};
var restore = function (email) {
    return axios.post("/account/recover", {
        email: email,
    });
};
var logout = function () {
    return axios({
        method: "post",
        url: "/account/logout",
    });
};
var AccountModel = {
    signIn: signIn,
    register: register,
    restore: restore,
    logout: logout,
};
export default AccountModel;
