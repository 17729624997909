import { makeStyles } from "@material-ui/core/styles";
import zIndex from "@material-ui/core/styles/zIndex";
import grey from "@material-ui/core/colors/grey";
export const drawerWidth = 360;

export const useStyles = makeStyles((theme) => ({
    appbar: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: theme.spacing(2),
        maxWidth: "100%",
        [theme.breakpoints.up("lg")]: {
            maxWidth: `calc(100vw - ${drawerWidth}px)`,
        },

        [theme.breakpoints.down("sm")]: {
            "& .MuiGrid-root > .MuiGrid-item:nth-of-type(2)": {
                order: 99,
                paddingBottom: theme.spacing(2),
            },
        },
    },

    mainColumn: {
        backgroundColor: grey[300],
        display: "flex",
        flexGrow: 2,
        flexDirection: "column",
        minHeight: "calc(100vh - 100px)",
        [theme.breakpoints.down("md")]: {
            width: "100vw",
        },

        "& .content": {
            flex: 1,
            [theme.breakpoints.up("md")]: {
                paddingTop: theme.spacing(3),
                paddingLeft: theme.spacing(3),
            },
        },
    },

    footer: {
        display: "flex",
        paddingTop: theme.spacing(3),
        marginTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#FFF",
        borderTop: "1px solid #CCC",
        justifyContent: "center",

        "& .MuiLink-root": {
            display: "block",
            color: "inherit",
            marginTop: theme.spacing(1),
        },
    },

    mobile: {
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },

    desktop: {
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
    },

    links: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        "& .MuiButton-label": {
            fontSize: "16px",
        },
        "& .MuiLink-root, & .MuiButton-root": {
            margin: theme.spacing(1),
        },
        "& .MuiIconButton-root": {
            [theme.breakpoints.up("lg")]: {
                display: "none",
            },
        },
    },
    logo: {
        width: "auto",
        maxWidth: "100%",
    },
    sidebar: {
        position: "sticky",
        top: 0,
        padding: "2rem 0 0 0",
        boxSizing: "border-box",
        width: `${drawerWidth}px`,
        boxShadow: "0 0 10px rgba(0,0,0,.25)",
        height: "100vh",
        [theme.breakpoints.down("md")]: {
            position: "fixed",
            left: `-${drawerWidth}px`,
            top: 0,
            background: "#FFFFFF",
            zIndex: zIndex.drawer,
        },
        transition: "left 0.4s",
        "& .close": {
            backgroundColor: "transparent",
            zIndex: 9999,
            position: "fixed",
            top: "1.5rem",
            right: "1rem",
            left: "auto",
            display: "none",
        },
        [theme.breakpoints.down("xs")]: {
            "& .close": {
                display: "inline",
            },
        },
    },

    ["sidebar--open"]: {
        left: 0,
    },

    "sidebar--mobile": {
        display: "none",
        [theme.breakpoints.down("md")]: {
            display: "block",
        },
    },

    menuItem: {
        paddingLeft: "1.5rem",
    },

    overlay: {
        zIndex: 1000,
    },

    content: {},
}));
