import { Typography } from "@material-ui/core";
import * as React from "react";
import config from "../config/config";
import { formatNumToCurrencyStyle } from "../util/helperFuncs";
var UserInfo = function (props) {
    var balanceToShow = Number(props.user.company.balance) + Number(props.user.company.limit);
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, null, config.app_name),
        React.createElement(Typography, null, props.user.company.name),
        React.createElement(Typography, null,
            "\u041E\u0441\u0442\u0430\u0442\u043E\u043A \u043D\u0430 \u0441\u0447\u0435\u0442\u0435: ",
            formatNumToCurrencyStyle(balanceToShow))));
};
export default UserInfo;
