module.exports = {
    companies: require("./companies.json"),
    users: require("./users.json"),
    user_groups: require("./user_groups.json"),
    product_categories: require("./product_categories.json"),
    products: require("./products.json"),
    orders: require("./orders.json"),
    reports: require("./reports.json"),
    person_types: require("./person_types.json"),
    invoices: require("./papers.json"),
    supporting: require("./papers.json"),
    limits: require("./limits.json"),
};
