var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import TestImg from "../assets/img/testImg";
import moment from "moment";
import CartModel from "../model/Cart";
/**
 * Returns string with formatted number for "ru"
 * @param summ number to format
 */
export var formatNumToCurrencyStyle = function (summ) {
    return new Intl.NumberFormat("ru", { style: "currency", currency: "RUB" }).format(summ);
};
/**
 * changes image to default
 * @param element element to change image in
 */
export var changeImageHandler = function (element) {
    if (element) {
        element.style.backgroundImage = "url(" + TestImg + ")";
    }
};
/**
 * Groups given items by given property
 * @param items array of objects
 * @param property string of property to group by
 * @returns object of grouped items
 */
export var groupByFunc = function (items, property) {
    return items.reduce(function (sortedItem, item) {
        var key = item[property];
        if (!sortedItem[key]) {
            sortedItem[key] = [];
        }
        sortedItem[key].push(item);
        return sortedItem;
    }, {});
};
/**
 *  Formats values for rendering
 * @param {Object[]} arr
 * @returns array of elements type Date
 */
export var formatArrToDates = function (arr) {
    if (arr.every(function (item) { return item instanceof Date; })) {
        return arr;
    }
    var formattedArr = [];
    for (var _i = 0, arr_1 = arr; _i < arr_1.length; _i++) {
        var item = arr_1[_i];
        var createdDate = new Date(item.year, item.month - 1, item.day);
        formattedArr.push(createdDate);
    }
    return formattedArr;
};
/**
 * Format data for user to be more readable
 * @param dateString raw date from server
 * @returns formated date more readable for user
 */
export var formatDateToShow = function (dateString) {
    if (!dateString)
        return;
    var splitDateStringArr = dateString.split(" ");
    var dateToShow = moment(splitDateStringArr[0], "YYYY-MM-DD").format("DD-MM-YYYY");
    if (splitDateStringArr[1])
        dateToShow += " " + splitDateStringArr[1];
    return dateToShow;
};
export var formatStatusBeforeShowing = function (statusItems, rawStatus) {
    if (!rawStatus)
        return;
    if (Object.keys(statusItems).includes(rawStatus)) {
        return statusItems[rawStatus];
    }
    return rawStatus;
};
/**
 * Returns number of items in cart in local storage
 * @returns items in cart
 */
export var getItemsNumInCart = function () {
    var localStorageCart = CartModel.get();
    var itemsInCart = 0;
    if (localStorageCart) {
        itemsInCart = localStorageCart.items.length;
    }
    return itemsInCart;
};
/**
 * Returns filtered array of orders, to add username to every order
 * @param {Object[]} AllItems
 * @returns
 */
export var filterAllItems = function (AllItems) {
    return AllItems.map(function (item) {
        if (item["user"]) {
            var updatedItem = __assign({}, item);
            var userName = item["user"]["person"] || item["user"];
            updatedItem["user"] = userName || "";
            return updatedItem;
        }
        return item;
    });
};
/**
 * Checks if there are any items
 * @param items
 * @returns true or false
 */
export var areItems = function (items) { return items && items.length > 0; };
/**
 * Switches label in properties list
 * @param fields
 * @param param1
 * @returns
 */
export var switchLabels = function (fields, _a) {
    var keyWithLabel = _a.keyWithLabel, newLabels = _a.newLabels;
    var updatedFields = __assign({}, fields);
    for (var lang in updatedFields[keyWithLabel].labels) {
        if (!Object.keys(newLabels).includes(lang))
            continue;
        updatedFields[keyWithLabel].labels[lang] = newLabels[lang];
    }
    return updatedFields;
};
/**
 * Formats array of strings
 * @param arrToFormat
 * @returns
 */
export var listFormatter = function (arrToFormat) {
    var intl = Intl;
    var strArr = arrToFormat.map(function (item) { return item.toString(); });
    var formatter = new intl.ListFormat("ru", {
        style: "long",
        type: "conjunction",
    });
    return formatter.format(strArr);
};
/**
 * Checks if string is a correct email
 * @param usrInput
 * @returns
 */
export var isEmailCorrect = function (usrInput) {
    if (usrInput === "")
        return true;
    var emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return !!usrInput.match(emailPattern);
};
