var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { NavLink as Link } from "react-router-dom";
import clsx from "clsx";
import config from "../../config/config";
import { useStyles } from "../../theme/frame.js";
import Typography from "@material-ui/core/Typography";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { MenuTypes, getMenu } from "../../config/routes";
var MENU_ITEM_COMMON_PROPS = { pt: 1, pb: 1 };
var SideBar = function (props) {
    var type = props.type.toString() === "admin"
        ? MenuTypes["admin"]
        : MenuTypes["partner"]; // ts requirements
    var classes = useStyles();
    var userMenu = getMenu(type);
    var menuItems = userMenu.map(function (item, key) {
        var children;
        if (item.items && Array.isArray(item.items)) {
            children = item.items.map(function (subItem, subKey) { return (React.createElement(ListItem, { button: true, key: "menu_" + type + "_" + key + "_" + subKey, component: Link, to: item.route + subItem.route },
                React.createElement(Box, __assign({ pl: 5 }, MENU_ITEM_COMMON_PROPS), subItem.label[config.app_language]))); });
        }
        return (React.createElement(React.Fragment, { key: "f_" + key },
            React.createElement(ListItem, { button: true, key: "menu_" + type + "_" + key, component: children ? Typography : Link, variant: "inherit", to: item.route },
                React.createElement(Box, __assign({ pl: 2 }, MENU_ITEM_COMMON_PROPS), item.label[config.app_language])),
            children && children.length > 0 ? (React.createElement(List, { key: "submenu_" + type + "_" + key }, children)) : null));
    });
    return (React.createElement(Box, { className: clsx(classes.sidebar, props.open && classes["sidebar--open"]) },
        React.createElement(List, { onClick: props.sideBarCloseHanlder }, menuItems),
        props.open && (React.createElement(IconButton, { onClick: props.sideBarCloseHanlder, className: "close" },
            React.createElement(CloseIcon, null)))));
};
export default SideBar;
