/**
 *
 * Generic class for api entities
 *
 */

// const axios = require("axios");
const schemas = require("./schema/index");
//import apiConfig from "../config/api";
const apiConfig = require("../config/config").default;
const moment = require("moment");
const faker = require("faker");
const _axios = require("axios");

const axios = _axios.create({
    withCredentials: true,
});

class Entity {
    modelName = "";
    schema = null;

    /**
     *
     * Constructor function
     *
     * @param {string} modelName    Must match some table "./schema/*.json"
     *
     * @param {string} baseEntity   If entity is related to some other entity, set base entity name
     *                              e.g. if we need to fetch configuration entries
     *                              of particular hotel with id = 1
     *                              modelName will be "configurations"
     *                              and baseEntity will be "hotels"
     *                              and baseID will be 1
     * @param {integer} baseId      Base entity id. See baseEntity
     *
     * @param {boolean} isFake      whether to use real API or Faker to provide values
     *
     */

    constructor(modelName, baseEntity = null, baseId = 0, isFake = false) {
        this.modelName = modelName;
        this.basePath = baseEntity ? `/${baseEntity}/${baseId}` : "";
        this.dataPath = apiConfig.baseUrl + this.basePath;
        this.schema = schemas[modelName];
        this.baseEntity = baseEntity;
        this.baseId = baseId;
        this.isFake = isFake;
    }

    /**
     *
     * Prepares recieved object from API to be used in frontend
     * Flattens data property
     *
     * @param {object} Item
     *
     */

    _one = (item) => {
        const processedItem = {};
        const entityFields = Object.keys(this.schema).filter(
            (field) => field !== "data" && field !== "relations"
        );

        const entityDataFields =
            (this.schema.data && Object.keys(this.schema.data)) || [];

        entityFields.forEach((field) => {
            const value = item.hasOwnProperty(field)
                ? item[field]
                : this.schema[field].hasOwnProperty("default")
                ? this.schema[field].default
                : null;
            processedItem[field] = value;
        });

        entityDataFields.forEach((field) => {
            const value =
                item.data && item.data.hasOwnProperty(field)
                    ? item.data[field]
                    : this.schema.data[field].hasOwnProperty("default")
                    ? this.schema.data[field].default
                    : null;
            processedItem[field] = value;
        });

        return processedItem;
    };

    /**
     *
     * Prepares recieved from API objects collection
     * to be used in frontend
     *
     *
     * @param {object} Item
     *
     */

    _many = (data) => {
        if (Array.isArray(data)) {
            return data.map((item) => this._one(item));
        } else if (typeof data === "object") {
            return this._one(data);
        }
    };

    /**
     *
     * Filters out excerpt fields before sending an object
     *
     * Fileds to be filtered (according to given entity schema )
     * - auto_increment fields
     * - fields that are marked with editable=false
     * - password fields, if they are empty
     *
     */

    _filter = (item, ignoreEditable = false) => {
        const filteredItem = { ...item };
        const schemaFields = this.getSchemaFields();

        Object.keys(item).map((field) => {
            if (
                !schemaFields.hasOwnProperty(field) ||
                schemaFields[field].type === "ai" ||
                schemaFields[field].type === "computed"
            ) {
                return delete filteredItem[field];
            }

            if (
                schemaFields[field].type === "list_objects" &&
                schemaFields[field].field
            ) {
                return (filteredItem[field] = filteredItem[field].map(
                    (item) => item[schemaFields[field].field]
                ));
            }
            if (schemaFields[field].editable === false && !ignoreEditable) {
                return delete filteredItem[field];
            }

            if (
                schemaFields[field].type === "password" &&
                (!filteredItem[field] ||
                    filteredItem[field].trim().length === 0)
            ) {
                return delete filteredItem[field];
            }

            if (
                schemaFields[field].type === "relation" &&
                schemaFields[field].entity === this.baseEntity
            ) {
                filteredItem[field] = this.baseId;
            }
        });

        if (!this.getSchemaRelations() || typeof item.relations !== "object") {
            return filteredItem;
        }

        Object.entries(item.relations).map((entry) => {
            const [relatedEntity, relatedValues] = [...entry];

            if (relatedValues.target) return;
            if (
                !Array.isArray(relatedValues) &&
                typeof relatedValues === "object"
            )
                return;
            const relatedFieldName = relatedEntity.replace(/s$/, "_id");
            filteredItem[relatedFieldName] = Array.isArray(relatedValues)
                ? [...relatedValues]
                : [relatedValues];
        });

        delete filteredItem.relations;

        return filteredItem;
    };

    /**
     *
     * Return dummy object  with all the necesary fields
     * to be used as initial value in Add new object Form
     *
     */

    getEmptyObject = () => {
        const emptyObject = {};
        Object.entries(this.getSchemaFields()).map((entry) => {
            const [fieldName, fieldConfig] = [...entry];
            const defaultValue = fieldConfig.hasOwnProperty("default")
                ? fieldConfig.default
                : null;

            emptyObject[fieldName] = defaultValue;
            if (fieldConfig.type === "date") {
                emptyObject[fieldName] = moment()
                    .add(defaultValue, "day")
                    .format("YYYY-MM-DD");
            }
            if (
                fieldConfig["type"] === "relation" &&
                fieldConfig["entity"] === this.baseEntity
            ) {
                emptyObject[fieldName] = this.baseId;
            }
        });

        if (!this.getSchemaRelations()) return emptyObject;
        emptyObject.relations = {};
        Object.entries(this.getSchemaRelations()).map((entry) => {
            const [relatedEntity, fieldConfig] = [...entry];
            emptyObject.relations[relatedEntity] = { ...fieldConfig };
        });
        return emptyObject;
    };

    /**
     *
     * Returns enitity model ( and mysql table) name
     *
     */

    getModelName = () => this.modelName;

    /**
     *
     * Returns object schema
     *
     */
    getSchema = () => this.schema;

    /**
     *
     * Returns schema fields
     *
     */
    getSchemaFields = () => {
        const schemaFields = { ...this.schema };
        delete schemaFields.relations;
        return schemaFields;
    };
    /**
     *
     * Returns entity relations
     * Relations are stored in a separate table like
     * | relation_id | entity1_id | entity2_id |
     *
     */
    getSchemaRelations = () =>
        this.schema.relations &&
        typeof this.schema.relations === "object" &&
        Object.keys(this.schema.relations).length > 0
            ? this.schema.relations
            : null;

    /**
     *
     * Generates typical routes for frontend
     *
     *
     * @return {object}
     *
     */

    routes = () => {
        const home = this.basePath + "/" + this.modelName;

        return {
            home,
            allItems: () => home,
            add: () => `${home}/add`,
            view: (id) => `${home}/${id}`,
            edit: (id) => `${home}/${id}/edit`,
            bound: (id, boundModel) => `${home}/${id}/${boundModel}`,
            addBound: (id, boundModel) => `${home}/${id}/${boundModel}/add`,
            base: () => `/${this.baseEntity}`,
        };
    };

    /**
     * API method
     * Fetches list of all items
     * if paginataion is enabled - provides pagination as well
     */

    getAll = (params = {}, cancelToken) => {
        const path = this.dataPath + "/" + this.modelName;

        return this.isFake
            ? Promise.resolve(this._getFakeMany(10))
            : axios
                  .get(path, { params: { ...params }, cancelToken })
                  .then((response) =>
                      response.data &&
                      response.data.current_page &&
                      response.data.data
                          ? {
                                ...response.data,
                                data: this._many(response.data.data),
                            }
                          : this._many(response.data)
                  );
    };

    /**
     * API method
     * Updates array of items
     *
     * @param {array} items
     *
     *
     */

    updateAll = (items) => {
        const itemsToUpdate = items.map((item) => this._filter(item));
        return axios.post(this.dataPath + "/" + this.modelName, itemsToUpdate);
    };

    /**
     * API method
     * Fetches particular item
     *
     * @param {integer} id  item id
     * @param {object} params url query parameters
     *
     *
     */

    getOne = (id, params = {}) => {
        return this.isFake
            ? Promise.resolve(this._getFake(id))
            : axios
                  .get(this.dataPath + "/" + this.modelName + "/" + id, {
                      params,
                  })
                  .then((response) => this._one(response.data));
    };

    /**
     * API method
     * Updates particular item
     *
     * @param {object} item
     *
     *
     */

    updateOne = (item) => {
        const id = item.id;
        const itemToUpdate = this._filter(item);

        return axios.put(
            this.dataPath + "/" + this.modelName + "/" + id,
            itemToUpdate
        );
    };

    /**
     * API method
     * Adds new item
     *
     * @param {object} item
     *
     *
     */

    addOne = (item) => {
        const itemToAdd = this._filter(item, true);

        return axios.post(`${this.dataPath}/${this.modelName}`, itemToAdd);
    };

    /**
     *
     * Shorthand to update only "Active" field for single record
     *
     * @param {integer} id      record id
     * @param {boolean} status  new state for active
     */

    toggle = (id, status) => {
        const updateFields = { active: status };
        return this.patch(id, updateFields);
    };

    /**
     * API method
     * Updates record
     *
     * @param {integer} id              record id
     * @param {object} updateFields     fields to update
     *
     *
     */

    patch(id, updateFields) {
        return axios.patch(
            this.dataPath + "/" + this.modelName + "/" + id,
            updateFields
        );
    }

    /**
     *
     * API method
     * Removes item
     *
     * @param {*} id
     */

    remove(id) {
        return axios.delete(this.dataPath + "/" + this.modelName + "/" + id);
    }

    /**
     *
     * Relation items operations
     *
     */

    relations = (id, relatedModelName) => {
        const relatedRoute =
            apiConfig.baseUrl +
            "/" +
            this.modelName +
            "/" +
            id +
            "/" +
            relatedModelName;
        const directRoute = apiConfig.baseUrl + "/" + relatedModelName;

        return {
            query: (query) => axios.get(`${directRoute}?query=${query}`),
            id: (id) => axios.get(`${directRoute}/${id}`),
            getRelated: () =>
                axios
                    .get(relatedRoute)
                    .then((response) =>
                        this.filterRelated(response, relatedModelName)
                    ),
            addRelation: (relId) => axios.post(relatedRoute + "/" + relId),
            removeRelation: (relId) => axios.delete(relatedRoute + "/" + relId),
        };
    };

    /**
     *
     * Filters realted entities
     *
     */

    filterRelated = (response, entityName) => {
        const relatedEntity = new Entity(entityName);
        if (response && response.data) {
            return relatedEntity._many(response.data);
        }
    };

    /**
     *
     * Returns fake object
     *
     */

    _getFake = (id = 0) => {
        const object = { id };
        Object.entries(this.getSchemaFields()).map((entry) => {
            const [fieldName, fieldConfig] = [...entry];
            if (fieldName == "id") {
                return;
            }
            const defaultValue = fieldConfig.hasOwnProperty("default")
                ? fieldConfig.default
                : null;
            object[fieldName] = defaultValue;

            if (fieldConfig.type === "entity") {
                const relatedModel = new Entity(
                    fieldConfig.entity,
                    null,
                    0,
                    true
                );
                const relatedId = faker.datatype.number();
                const relations = this.getSchemaRelations();
                const targetFieldName =
                    relations &&
                    relations[fieldConfig.entity] &&
                    relations[fieldConfig.entity].target;
                if (targetFieldName) {
                    object[targetFieldName] = relatedId;
                }
                const fakeEntity = relatedModel._getFake(relatedId);
                object[fieldName] = { ...fakeEntity };
            }

            if (fieldConfig.type === "date") {
                object[fieldName] = moment()
                    .add(defaultValue, "day")
                    .format("YYYY-MM-DD");
            }

            if (fieldConfig.type === "array") {
                object[fieldName] = [];
            }

            if (fieldConfig.faker) {
                let fakerFields = fieldConfig.faker;
                if (!Array.isArray(fakerFields)) {
                    fakerFields = [fakerFields];
                }
                const fakedValues = [];
                fakerFields.forEach((f) => {
                    const [fakerSection, fakerProp] = f.split(".");
                    try {
                        const fakedValue = faker[fakerSection][fakerProp]();
                        fakedValues.push(fakedValue);
                    } catch (e) {
                        console.log(
                            "Faker property " + f + " generation error"
                        );
                    }
                });
                if (fakedValues.length > 0) {
                    object[fieldName] = fakedValues.join(" ");
                }
            }
        });

        if (!this.getSchemaRelations()) return object;
        object.relations = {};
        Object.entries(this.getSchemaRelations()).map((entry) => {
            const [relatedEntity, fieldConfig] = [...entry];

            const relatedModel = new Entity(relatedEntity, null, 0, true);
            const relatedId = faker.datatype.number();
            object[fieldConfig.target] = relatedId;
            object.relations[relatedEntity] = relatedModel.getOne(relatedId);
        });
        return object;
    };

    _getFakeMany(qty = 1) {
        if (qty < 1) qty = 1;
        const result = [];
        for (let i = 0; i < qty; i++) {
            result.push(this._getFake(i));
        }
        return result;
    }
}

module.exports = Entity;
