var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import moment from "moment";
var LOCALSTORAGE_NAME = "cart";
var DATE_FORMAT = "YYYY-MM-DD";
var CartModel = /** @class */ (function () {
    function CartModel() {
        throw new Error("Cart can't be constructed use static method instead");
    }
    CartModel.get = function () {
        var cart = {
            items: [],
            customer: "",
            modified: moment().valueOf(),
        };
        var cartFromStorage = localStorage.getItem(LOCALSTORAGE_NAME);
        if (cartFromStorage) {
            cart = CartModel.filter(JSON.parse(cartFromStorage));
        }
        return cart;
    };
    CartModel.getSingleItemNum = function (item) {
        var cartItems = CartModel.get().items;
        var num = 0;
        for (var _i = 0, cartItems_1 = cartItems; _i < cartItems_1.length; _i++) {
            var cartItem = cartItems_1[_i];
            if (parseInt(cartItem.rateId) === item.id &&
                cartItem.person_type_id === item.variants[0].person_type_id &&
                cartItem.carrier_type === item.variants[0].carrier_type) {
                num += 1;
            }
        }
        return num;
    };
    CartModel.set = function (data) {
        var cartToLocalStorage = JSON.stringify(data);
        localStorage.setItem(LOCALSTORAGE_NAME, cartToLocalStorage);
        window.dispatchEvent(new Event("storage"));
    };
    CartModel.removeCart = function () {
        localStorage.removeItem(LOCALSTORAGE_NAME);
        window.dispatchEvent(new Event("storage"));
    };
    CartModel.changeBuyerName = function (name) {
        var cart = CartModel.get();
        cart.customer = name;
        cart.modified = moment().valueOf();
        CartModel.set(cart);
    };
    CartModel.changeIndivByerName = function (indivCustomer) {
        var cart = CartModel.get();
        cart.indivCustomer = indivCustomer;
        cart.modified = moment().valueOf();
        CartModel.set(cart);
    };
    CartModel.addItem = function (item) {
        var cart = CartModel.get();
        cart.items.push(item);
        cart.modified = moment().valueOf();
        CartModel.set(cart);
    };
    CartModel.addItems = function (items) {
        for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
            var item = items_1[_i];
            CartModel.addItem(item);
        }
    };
    CartModel.filter = function (data) {
        var _a;
        var filteredData = __assign({}, data);
        var now = moment().format(DATE_FORMAT);
        var filteredItems = (_a = data.items) === null || _a === void 0 ? void 0 : _a.filter(function (item) {
            var usageDateStr = moment(item.usageDate).format(DATE_FORMAT);
            var usageDateMoment = moment(usageDateStr);
            if (!moment(usageDateMoment).isBefore(now, "date"))
                return item;
        });
        filteredData.items = filteredItems;
        return filteredData;
    };
    return CartModel;
}());
export default CartModel;
