import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";

const Message = ({ message, setMessage }) => {
    let history = useHistory();

    return (
        <Backdrop
            style={{ zIndex: 2001 }}
            open={!!message}
            onClick={() => {
                setMessage(null);
                history.push("/");
            }}
        >
            <Paper elevation={0}>
                <Box p={6}>{message}</Box>
            </Paper>
        </Backdrop>
    );
};

export default Message;
