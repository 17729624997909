import clientConfig from "../../config/client";
import moment from "moment";

export const monthNames = moment.locale(clientConfig.locale) && moment.months();
export const weekDays =
    moment.locale(clientConfig.locale) && moment.weekdaysShort(true);

export const sortableFields = [
    "id",
    "type",
    "name",
    "active",
    "key",
    "value",
    "order",
    "created_at",
    "date_in",
    "date_out",
    "total",
    "status",
    "number",
    "guestName",
    "roomName",
];

export const namesForIndicator = [
    "active",
    "card_guarantee",
    "payment_required",
    "scope_individual",
    "scope_partners",
    "scope_channel",
];

export const FIRST_PAGE = "first_page";
export const PREV_PAGE = "previous_page";
export const NEXT_PAGE = "next_page";
export const LAST_PAGE = "last_page";

export const ASC = "asc";
export const DESC = "desc";

export const SEARCH_FIELDS_FOR_STRING = [
    "name",
    "login",
    "first_name",
    "last_name",
    "email",
    "type",
];

export const SEARCH_FIELDS = {
    hotels: { name: "" },
    rates: { name: "", active: "", date_start: "", date_end: "" },
    reservations: {
        created_at: "",
        number: "",
        guest: "",
        date_in: "",
        date_out: "",
        room_type_id: "",
        status: "",
        total: "",
    },
    users: {
        login: "",
        type: "",
        email: "",
        hotels: "",
        first_name: "",
        last_name: "",
    },
    operators: {
        login: "",
        active: "",
        email: "",
        first_name: "",
        last_name: "",
    },
};

export const COMPARE_VALUES = [
    { "=": "eq:", title: "равно" },
    { ">": "gt:", title: "больше" },
    { "<": "lt:", title: "меньше" },
];

export const ENTITIES_WITHOUT_SEARCH = [
    "configurations",
    "reports",
    "room_types",
    "payment_methods",
];

export const PARTNER = "partner";
export const ADMIN = "admin";
