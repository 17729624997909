import React, { createContext } from "react";

import Entity from "../model/Entity";
const userDS = new Entity("users");
import schemas from "../model/schema/index";

const initialValue = {
    user: {
        ...schemas.users,
        ...userDS.getEmptyObject(),
    },
    actions: {
        logout: React.MouseEvent,
    },
    inCartNum: 0,
};

export default createContext({ ...initialValue });
