import React, { useState, lazy, Suspense } from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import "./assets/style/app.scss";
import AccountModel from "./model/Account";
import AccountContext from "./context/AccountContext";
import PersonalContext from "./context/PersonalContext";
import AppBarComponent from "./components/AppbarComponent/AppbarComponent";
import theme from "./theme/theme";
import Message from "./theme/UI/Message";

import { BrowserRouter as Router } from "react-router-dom";
import Entity from "./model/Entity";

const NotAuthorized = lazy(() => import("./layouts/NotAuthorized.js"));
const Authorized = lazy(() => import("./layouts/Authorized.tsx"));

const App = () => {
    const [message, setMessage] = useState();
    const userDS = new Entity("users");
    const [user, setUser] = useState(null);
    const updateBalance = (newBalance) => {
        setUser((prevState) => ({
            ...prevState,
            company: { ...prevState.company, balance: newBalance },
        }));
    };

    const logout = () =>
        AccountModel.logout()
            .then(() => setUser(null))
            .catch((error) => {
                if (error.response?.status == 401) {
                    setUser(null);
                    return alert("Ваша сессия была завершена");
                }

                alert("Не удалось завершить сессию");
            });

    return (
        <Router basename="/">
            <MuiThemeProvider theme={theme}>
                {user ? (
                    <PersonalContext.Provider
                        value={{
                            user,
                            updateBalance,
                            message: setMessage,
                            setUser,
                            actions: {
                                logout: logout,
                            },
                        }}
                    >
                        <Suspense fallback={<p>...</p>}>
                            <Authorized>
                                <AppBarComponent
                                    type={user.type.toString()}
                                    logout={logout}
                                    user={user}
                                />
                            </Authorized>
                        </Suspense>
                    </PersonalContext.Provider>
                ) : (
                    <AccountContext.Provider
                        value={{
                            user,
                            message: setMessage,
                            actions: {
                                signIn: (email, password) => {
                                    AccountModel.signIn(email, password)
                                        .then((response) => {
                                            const user = userDS._one(
                                                response.data
                                            );
                                            setUser(user);
                                        })
                                        .catch((_e) => {
                                            if (email || password) {
                                                alert("Ошибка");
                                            }
                                        });
                                },
                                register: (
                                    inn,
                                    person,
                                    phone,
                                    email,
                                    file,
                                    payment,
                                    password
                                ) => {
                                    return AccountModel.register(
                                        inn,
                                        person,
                                        phone,
                                        email,
                                        file,
                                        payment,
                                        password
                                    );
                                },
                                restore: (term) => AccountModel.restore(term),
                            },
                        }}
                    >
                        <Suspense fallback={<p>...</p>}>
                            <NotAuthorized />
                        </Suspense>
                    </AccountContext.Provider>
                )}
                <Message message={message} setMessage={setMessage} />
            </MuiThemeProvider>
        </Router>
    );
};

export default App;
