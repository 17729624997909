var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
var menu = require("./menu.json");
var nonMenuRoutes = require("./rest_methods.json");
export var MenuTypes;
(function (MenuTypes) {
    MenuTypes["admin"] = "admin";
    MenuTypes["partner"] = "partner";
})(MenuTypes || (MenuTypes = {}));
export function getNonMenuRoutes(type) {
    var result = {};
    nonMenuRoutes[type].forEach(function (element) {
        var path = element.route;
        var name = path.replace(/^\//, "");
        if (Reflect.has(element, "label")) {
            name = element.label;
        }
        return (result[name] = path);
    });
    return result;
}
function getRoutes(type) {
    var result = {};
    menu[type].forEach(function (element) {
        var path = element.route;
        var name = path.replace(/^\//, "");
        if (!element.items)
            return (result[name] = path);
        element.items.forEach(function (subItem) {
            var subPath = path + subItem.route;
            var subName = name + "_" + subItem.route.replace(/^\//, "");
            result[subName] = subPath;
        });
    });
    return result;
}
export function getMenu(type, additionalItems) {
    var result = menu[type];
    if (!additionalItems)
        return result;
    var additionalArray = Array.isArray(additionalItems)
        ? additionalItems
        : [additionalItems];
    result.forEach(function (menuItem) {
        if (additionalArray.length == 0)
            return;
        for (var i = 0; i < additionalArray.length; i++) {
            if (additionalArray[i].parent === menuItem.route) {
                if (!menuItem.items)
                    menuItem.items = !menuItem.items
                        ? __spreadArray([], additionalArray[i].items) : __spreadArray(__spreadArray([], menuItem.items), additionalArray[i].items);
            }
        }
    });
    return result;
}
export var partnerNonMenuRoutes = getNonMenuRoutes(MenuTypes.partner);
export var partnerRoutes = getRoutes(MenuTypes.partner);
export var adminRoutes = getRoutes(MenuTypes.admin);
export default getRoutes;
